#sidebar {
  padding: 2.5em 2.5em 0.5em 2.5em;
  background: #161e2d;
  cursor: default;
  height: 100vh;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  text-align: right;
  top: 0;
  width: 18em;
  z-index: 10000;
}

#sidebar > .inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateY(0);
  transition: opacity 1s ease;
  min-height: 100%;
  opacity: 1;
  width: 100%;
}

#sidebar nav > div.heading {
  text-transform: uppercase;
  font-size: 0.8em;
  color: #ffffff;
  font-weight: 100;
  margin-top: 5em;
  text-align: left;
}

#sidebar nav > ul {
  list-style: none;
  padding: 0;
}

#sidebar nav > ul.resources {
}

#sidebar nav li {
  transform: translateY(0);
  transition: opacity 0.15s ease, transform 0.75s ease;
  margin: 1em 0 0 0;
  opacity: 1;
  padding: 0;
  position: relative;
}

#sidebar nav li:first-child {
  margin: 0;
}

#sidebar nav li:nth-child(1) {
  transition-delay: 0.45s;
}

#sidebar nav li:nth-child(2) {
  transition-delay: 0.65s;
}

#sidebar nav li:nth-child(3) {
  transition-delay: 0.85s;
}

#sidebar nav li:nth-child(4) {
  transition-delay: 1.05s;
}

#sidebar nav li:nth-child(5) {
  transition-delay: 1.25s;
}

#sidebar nav li:nth-child(6) {
  transition-delay: 1.45s;
}

#sidebar nav li:nth-child(7) {
  transition-delay: 1.65s;
}

#sidebar nav li:nth-child(8) {
  transition-delay: 1.85s;
}

#sidebar nav li:nth-child(9) {
  transition-delay: 2.05s;
}

#sidebar nav li:nth-child(10) {
  transition-delay: 2.25s;
}

#sidebar nav li:nth-child(11) {
  transition-delay: 2.45s;
}

#sidebar nav li:nth-child(12) {
  transition-delay: 2.65s;
}

#sidebar nav li:nth-child(13) {
  transition-delay: 2.85s;
}

#sidebar nav li:nth-child(14) {
  transition-delay: 3.05s;
}

#sidebar nav li:nth-child(15) {
  transition-delay: 3.25s;
}

#sidebar nav li:nth-child(16) {
  transition-delay: 3.45s;
}

#sidebar nav li:nth-child(17) {
  transition-delay: 3.65s;
}

#sidebar nav li:nth-child(18) {
  transition-delay: 3.85s;
}

#sidebar nav li:nth-child(19) {
  transition-delay: 4.05s;
}

#sidebar nav li:nth-child(20) {
  transition-delay: 4.25s;
}

#sidebar nav a {
  transition: color 0.2s ease;
  border: 0;
  color: rgba(255, 255, 255, 0.35);
  display: block;
  font-size: 0.7em;
  font-weight: bold;
  letter-spacing: 0.25em;
  line-height: 1;
  outline: 0;
  padding: 1.35em 0;
  position: relative;
  text-decoration: none;
  text-transform: lowercase;
}

#sidebar nav ul.resources a {
  color: silver;
}

#sidebar nav ul.resources a:hover {
  color: royalblue;
}

#sidebar nav a:before,
#sidebar nav a:after {
  border-radius: 0.2em;
  bottom: 0;
  content: "";
  height: 0.2em;
  position: absolute;
  right: 0;
  width: 100%;
}

#sidebar nav a:before {
  background: #3c2c62;
}

#sidebar nav ul.resources a:before {
  background: none;
}

#sidebar nav a:after {
  background-image: linear-gradient(to right, #d45b07, #ffcc83);
  transition: max-width 0.2s ease;
  max-width: 0;
}

#sidebar nav a:hover {
  color: rgba(255, 255, 255, 0.55);
}

#sidebar nav a.active {
  color: #ffffff;
}

#sidebar nav a.active:after {
  max-width: 100%;
}

body.is-preload #sidebar > .inner {
  opacity: 0;
}

body.is-preload #sidebar nav ul li {
  transform: translateY(2em);
  opacity: 0;
}

#sidebar nav a:after {
  background-image: linear-gradient(to right, #d45b07, #ffcc83);
}

#sidebar .logo {
  margin-bottom: 1.5em;
}

#sidebar .logo img {
  width: 100%;
}

#sidebar .logo a {
  border-bottom: inherit;
}

#sidebar .contact {
  text-align: center;
  margin-top: 50px;
}

#sidebar .contact a {
  color: #ff9900;
  text-decoration: none;
  letter-spacing: 4px;
  font-size: 0.75em;
}
