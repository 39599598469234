/* Basic */

body {
  /* background: #232f3e; */
  margin-left: 18em;
}

body.is-preload *,
body.is-preload *:before,
body.is-preload *:after {
  animation: none !important;
  transition: none !important;
}

::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}
::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to bottom, #d45b07aa, #ffcc83aa, #d45b07aa);
}
::-webkit-scrollbar-track {
  background: none;
}

@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_Th.woff2") format("woff2");
  font-weight: 100;
}
@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_ThIt.woff2") format("woff2");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_Lt.woff2") format("woff2");
  font-weight: 200;
}
@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_LtIt.woff2") format("woff2");
  font-weight: 200;
  font-style: italic;
}
@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_Rg.woff2") format("woff2");
  font-weight: 400;
}
@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_RgIt.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_SBd.woff2") format("woff2");
  font-weight: 500;
}
@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_SBdIt.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_Bd.woff2") format("woff2");
  font-weight: 600;
}
@font-face {
  font-family: "Ember";
  src: url("./static/fonts/AmazonEmber_W_BdIt.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
}

body {
  font-family: "Ember";
  font-weight: 300;
  /* color: #e3e3e3; */
  background-attachment: fixed;
  /* background-image: url("./static/content.svg"); */
  background-position: top right;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
