main a {
  transition: color 0.2s ease, border-bottom-color 0.2s ease;
  border-bottom: dotted 1px rgba(197, 161, 255, 0.3);
  color: #c5a1ff;
  text-decoration: none;
}

main a:hover {
  border-bottom: solid 1px rgba(197, 161, 255, 0.8);
  color: #c5a1ff;
  text-decoration: none;
}

.edit-quip {
  text-align: center;
  font-size: 12px;
  margin-top: 50px;
}

main {
  max-width: 900px;
}

main > .container {
  padding-top: 50px;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

#root h1:first-of-type {
  display: none;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.2rem;
}
/*
blockquote,
q {
    display: block;
    margin: 1em 0;
    font-style: italic;
}

blockquote a,
q a {
    text-decoration: underline;
}

blockquote {
    padding-left: 10px;
    border-left: 4px solid #a6a6a6;
}

q {
    color: #a6a6a6;
    line-height: 40px;
    font-size: 24px;
    text-align: center;
    quotes: none;
}

q a {
    color: #a6a6a6;
}

code,
pre {
    font-family: Consolas, "Liberation Mono", Menlo, "Courier Prime Web",
        Courier, monospace;
    background: #f2f2f2;
}

code {
    padding: 1px;
    margin: 0 -1px;
    border-radius: 3px;
}

pre {
    display: block;
    line-height: 20px;
    text-shadow: 0 1px white;
    padding: 5px 5px 5px 30px;
    white-space: nowrap;
    position: relative;
    margin: 1em 0;
}

pre:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 15px;
    border-left: solid 1px #dadada;
}
*/

/* Text Alignment */
.line {
  margin-top: 0;
  margin-bottom: 0;
}

.align-center {
  text-align: center;
}

.align-right {
  text-align: end;
}

/* Lists */
div[data-section-style="5"],
div[data-section-style="6"],
div[data-section-style="7"] {
  margin: 12px 0;
}

ul {
  padding: 0 0 0 40px;
}

ul li {
  margin-bottom: 0.4em;
}

/* Bulleted list */
div[data-section-style="5"] ul {
  list-style-type: disc;
}
div[data-section-style="5"] ul ul {
  list-style-type: circle;
}
div[data-section-style="5"] ul ul ul {
  list-style-type: square;
}
div[data-section-style="5"] ul ul ul ul {
  list-style-type: disc;
}
div[data-section-style="5"] ul ul ul ul ul {
  list-style-type: circle;
}
div[data-section-style="5"] ul ul ul ul ul ul {
  list-style-type: square;
}

/* Numbered list */
div[data-section-style="6"] ul {
  list-style-type: decimal;
}
div[data-section-style="6"] ul ul {
  list-style-type: lower-alpha;
}
div[data-section-style="6"] ul ul ul {
  list-style-type: lower-roman;
}
div[data-section-style="6"] ul ul ul ul {
  list-style-type: decimal;
}
div[data-section-style="6"] ul ul ul ul ul {
  list-style-type: lower-alpha;
}
div[data-section-style="6"] ul ul ul ul ul ul {
  list-style-type: lower-roman;
}

/* Checklist */
div[data-section-style="7"] ul {
  list-style-type: none;
}

div[data-section-style="7"] ul li:before {
  content: "\2610";
  position: absolute;
  display: inline;
  margin-right: 1.2em;
  margin-left: -1.2em;
}

div[data-section-style="7"] ul li.parent:before {
  content: "";
}

div[data-section-style="7"] ul li.checked {
  text-decoration: line-through;
}

div[data-section-style="7"] ul li.checked:before {
  content: "\2611";
  text-decoration: none;
}

/* Tables */
div[data-section-style="8"] {
  margin: 12px 0;
}

table {
  color: #fff !important;
}

table th,
table td {
  border-color: #37534d !important;
  border-right: 1px solid;
}

table th:last-child,
td:last-child {
  border-right-style: none;
}

table th {
  background-color: #067f68 !important;
  font-size: small;
  border-top-style: none !important;
  border-bottom-style: none !important;
}

table tr:first-of-type td {
  border-top-style: none !important;
}

table th:first-child,
td:first-child {
  display: none;
}

table td {
  font-size: small;
}

table td.bold {
  font-weight: bold;
}

table td.italic {
  font-style: italic;
}

table td.underline {
  text-decoration: underline;
}

table td.strikethrough {
  text-decoration: line-through;
}

table td.underline.strikethrough {
  text-decoration: underline line-through;
}

.table-dark td {
  background-color: #343a40 !important;
}

/* Images */
div[data-section-style="11"] {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}

div[data-section-style="11"][data-section-float="0"] {
  clear: both;
  text-align: center;
}

div[data-section-style="11"][data-section-float="1"] {
  float: left;
  clear: left;
  margin-right: 20px;
}

div[data-section-style="11"][data-section-float="2"] {
  float: right;
  clear: right;
  margin-left: 20px;
}

div[data-section-style="11"] img {
  display: block;
  max-width: 100%;
  height: auto;
  margin: auto;
}

hr {
  background-color: #ff9900;
}

/* Apps */
div[data-section-style="19"].placeholder {
  margin: 0.8em auto;
  padding: 4px 0;
  display: block;
  color: #3d87f5;
  text-align: center;
  border: 1px solid rgba(41, 182, 242, 0.2);
  border-radius: 3px;
  background: #e9f8fe;
}

div[data-section-style="19"].first-party-element {
  margin-bottom: 10px;
  background-repeat: no-repeat;
  background-size: contain;
}

div[data-section-style="19"].first-party-element.kanban {
  background-image: url("https://quip-cdn.com/nK0hSyhsb4jrLIL2s5Ma-g");
  height: 166px;
}

div[data-section-style="19"].first-party-element.calendar {
  background-image: url("https://quip-cdn.com/OYujqLny03RILxcLIiyERg");
  height: 244px;
}

div[data-section-style="19"].first-party-element.poll {
  background-image: url("https://quip-cdn.com/fbIiFrcKGv__4NB7CBfxoA");
  height: 116px;
}

div[data-section-style="19"].first-party-element.countdown {
  background-image: url("https://quip-cdn.com/3bPhykD2dBei9sSjCWteTQ");
  height: 96px;
}

div[data-section-style="19"].first-party-element.process_bar {
  background-image: url("https://quip-cdn.com/ybQlHnHEIIBLog5rZmYs_w");
  height: 36px;
}

div[data-section-style="19"].first-party-element.project_tracker {
  background-image: url("https://quip-cdn.com/OFQU087b4Mxzz1ZaHwtjXA");
  height: 164px;
}

div[data-section-style="19"] img {
  margin: 0.5em;
}

div[data-section-style="19"] img.masked-image {
  margin: 0;
  transform-origin: top left;
}

div[data-section-style="19"] .image-mask {
  position: relative;
  overflow: hidden;
}
/*
 * Copyright 2019 Quip
 *
 * Licensed under the Apache License, Version 2.0 (the "License"); you may
 * not use this file except in compliance with the License. You may obtain
 * a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS, WITHOUT
 * WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied. See the
 * License for the specific language governing permissions and limitations
 * under the License.
 */

body {
  counter-reset: indent0 indent1 indent2 indent3 indent4 indent5 indent6 indent7
    indent8;
}

/* Numbered list */
div[data-section-style="6"] {
  counter-reset: indent0 indent1 indent2 indent3 indent4 indent5 indent6 indent7
    indent8;
}
div[data-section-style="6"].list-numbering-continue {
  counter-reset: none;
}
div[data-section-style="6"].list-numbering-restart-at {
  counter-reset: indent0 var(--indent0) indent1 indent2 indent3 indent4 indent5
    indent6 indent7 indent8;
}
div[data-section-style="6"] ul {
  /* indent0 is not reset since it is shared across the div elements */
  list-style-type: none !important;
}
div[data-section-style="6"] ul ul {
  counter-reset: indent1;
}
div[data-section-style="6"] ul ul ul {
  counter-reset: indent2;
}
div[data-section-style="6"] ul ul ul ul {
  counter-reset: indent3;
}
div[data-section-style="6"] ul ul ul ul ul {
  counter-reset: indent4;
}
div[data-section-style="6"] ul ul ul ul ul ul {
  counter-reset: indent5;
}
div[data-section-style="6"] ul li {
  counter-increment: indent0;
}
div[data-section-style="6"] ul ul li {
  counter-increment: indent1;
}
div[data-section-style="6"] ul ul ul li {
  counter-increment: indent2;
}
div[data-section-style="6"] ul ul ul ul li {
  counter-increment: indent3;
}
div[data-section-style="6"] ul ul ul ul ul li {
  counter-increment: indent4;
}
div[data-section-style="6"] ul ul ul ul ul ul li {
  counter-increment: indent5;
}
div[data-section-style="6"] ul li:before {
  content: counter(indent0, decimal) ". ";
}
div[data-section-style="6"] ul ul li:before {
  content: counter(indent1, lower-alpha) ". ";
}
div[data-section-style="6"] ul ul ul li:before {
  content: counter(indent2, lower-roman) ". ";
}
div[data-section-style="6"] ul ul ul ul li:before {
  content: counter(indent3, decimal) ". ";
}
div[data-section-style="6"] ul ul ul ul ul li:before {
  content: counter(indent4, lower-alpha) ". ";
}
div[data-section-style="6"] ul ul ul ul ul ul li:before {
  content: counter(indent5, lower-roman) ". ";
}
